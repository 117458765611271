import { createAsyncThunk } from "@reduxjs/toolkit";
import ExamBatchService from "src/services/ExamBatch/ExamBatch.service";

export const examBatch = createAsyncThunk(
  "batch-exam/examBatch",
  async (batch_id: number) => {
    return ExamBatchService.examBatch(batch_id);
  }
);

export const examPaperList = createAsyncThunk(
  "batch-exams/examPaperList",
  async (exam_paper_id: number) => {
    console.log('this is call ');
    return ExamBatchService.examPaperList(exam_paper_id);
  }
);

export const examPaperQuestion = createAsyncThunk(
  "batch-exams/examPaperQuestion",
  async (exam_student_id: number) => {
    console.log('this is call ');
    return ExamBatchService.examPaperQuestion(exam_student_id);
  }
);

export const addMarkById = createAsyncThunk(
  "area/editAreaById",
  async (data: any) => {
    return ExamBatchService.addMarkById(data);
  }
);

