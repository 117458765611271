import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAdmissionBasicRecord,
  IAdmissionCancelRecord,
  IAdmission,
  IAdmissionStatus,
  IUpdateAdmissionStatus,
  IAssignCourseInfo,
  AdmissionCourseCompleted,
  IPostalCommunicationData,
  IParentsDetailsData,
  IDocumentsData,
  IEducationAndProfessionDetailsData,
  IMissingAdmission,
  IInstallmentModify,
  ICRMLead,
  FinalCRMData,
  IPushCRMLeadEnquirie,
  ICounsellor,
  IConcessionDetails,
  IAutoFeedbackData,
} from "src/services/admission/admission.model";
import { IAddAdmission } from "src/pages/AddAdmission/addAdmission.model";
import {
  IRemark,
  IRemarkDetails,
} from "src/services/admission/admission.model";
import admissionService from "../../services/admission/admission.service";
import { IBranchTransferRecord } from "src/components/BranchTransfer/BranchTransfer.model";
import { ICourseModificationPayload } from "src/pages/CourseModificationModel/CourseModification.model";
import { IAddPenaltyFormValues } from "src/pages/PenaltyReceipt/AddPenalty/AddPenalty.model";
import { IPenaltyFilter } from "src/pages/PenaltyReceipt/PenaltyReceipt.model";

export const getCRMLeadEnquiries = createAsyncThunk(
  "admission/crmLeadEnquiries",
  async (data: IAdmission | URLSearchParams | {}) => {
    return admissionService.getCRMLeadEnquiries(data);
  }
);

export const pushCRMLeadEnquirie = createAsyncThunk(
  "admission/push-crm-lead-enquirie",
  async (data: IPushCRMLeadEnquirie) => {
    return admissionService.pushCRMLeadEnquirie(data);
  }
);

export const getCRMCounsellor = createAsyncThunk(
  "crm/get-crm-counsellor",
  async (data: ICounsellor | URLSearchParams | {}) => {
    return admissionService.getCRMCounsellor(data);
  }
);

export const searchAdmissionData = createAsyncThunk(
  "category/searchCategoryData",
  async (data: IAdmission | URLSearchParams | {}) => {
    return admissionService.searchAdmissionData(data);
  }
);

export const searchAdmissionCsvEmailSendData = createAsyncThunk(
  "category/searchAdmissionCsvEmailSendData",
  async (data: IAdmission | URLSearchParams | {}) => {
    return admissionService.searchAdmissionCsvEmailSendData(data);
  }
);

export const crmPullLead = createAsyncThunk(
  "crm/crmLeadData",
  async (data: ICRMLead | URLSearchParams | {}) => {
    return admissionService.pullCRMLead(data);
  }
);

export const crmPushLead = createAsyncThunk(
  "crm/crmPushLeadData",
  async ({ leadId, data }: { leadId: string, data: FinalCRMData[] | URLSearchParams | {} }) => {
    return admissionService.PushCRMLead(leadId, data);
  }
);

export const searchMissingAdmissionData = createAsyncThunk(
  "admissions/searchMissingAdmissionData",
  async (data: IMissingAdmission | URLSearchParams | {}) => {
    return admissionService.searchMissingAdmissionData(data);
  }
);

export const createAdmission = createAsyncThunk(
  "admissions/createAdmission",
  async (data: IAddAdmission) => {
    return admissionService.createAdmission(data);
  }
);
export const getAdmissionStatusData = createAsyncThunk(
  "category/getAdmissionStatusData",
  async (data: IAdmissionStatus | URLSearchParams | {}) => {
    return admissionService.getAdmissionStatusData(data);
  }
);
export const getAdmissionRemarksData = createAsyncThunk(
  "category/getAdmissionRemarksData",
  async (data: IRemark | URLSearchParams | {}) => {
    return admissionService.getAdmissionRemarksData(data);
  }
);

export const createNewRemark = createAsyncThunk(
  "category/createNewRemark",
  async (data: IRemarkDetails | URLSearchParams | {}) => {
    return admissionService.createNewRemark(data);
  }
);

export const createBulkRemark = createAsyncThunk(
  "category/createBulkRemark",
  async (data: IRemarkDetails | URLSearchParams | {}) => {
    return admissionService.createBulkRemark(data);
  }
);

export const cancelAdmission = createAsyncThunk(
  "zone/cancelAdmission",
  async (data: IAdmissionCancelRecord) => {
    return admissionService.cancelAdmission(data);
  }
);

export const editAdmissionBasicDataById = createAsyncThunk(
  "batch/editAdmissionBasicDataById",
  async (data: IAdmissionBasicRecord) => {
    return admissionService.editAdmissionBasicDataById(data);
  }
);

export const updateAdmissionStatus = createAsyncThunk(
  "admission/updateAdmissionStatus",
  async (data: IUpdateAdmissionStatus) => {
    return admissionService.updateAdmissionStatus(data);
  }
);

export const courseInfo = createAsyncThunk(
  "admission/courseInfo",
  async (id: number) => {
    return admissionService.courseInfo(id);
  }
);

export const assignBatch = createAsyncThunk(
  "admission/assignBatch",
  async (data: IAssignCourseInfo | undefined) => {
    return admissionService.assignBatch(data);
  }
);
export const unAssignBatch = createAsyncThunk(
  "admission/unAssignBatch",
  async (id: number | undefined) => {
    return admissionService.unAssignBatch(id);
  }
);
export const getGrIdData = createAsyncThunk(
  "admission/getGrIdData",
  async (data: number) => {
    return admissionService.getGrIdData(data);
  }
);

export const admissionBranchTransfer = createAsyncThunk(
  "admission/admissionBranchTransfer",
  async (data: IBranchTransferRecord) => {
    return admissionService.admissionBranchTransfer(data);
  }
);

export const courseModificationById = createAsyncThunk(
  "admission/courseModificationById",
  async (data: ICourseModificationPayload) => {
    return admissionService.courseModificationById(data);
  }
);

export const getAdmissionDetailsById = createAsyncThunk(
  "admission/getAdmissionDetailsById",
  async (id: number) => {
    return admissionService.getAdmissionDetailsById(id);
  }
);

export const courseCompleted = createAsyncThunk(
  "admission/courseCompleted",
  async (data: AdmissionCourseCompleted) => {
    return admissionService.courseCompleted(data);
  }
);

export const updatePostalCommunication = createAsyncThunk(
  "admission/updatePostalCommunication",
  async (data: IPostalCommunicationData) => {
    return admissionService.updatePostalCommunication(data);
  }
);

export const updateParentsDetails = createAsyncThunk(
  "admission/updateParentsDetails",
  async (data: IParentsDetailsData) => {
    return admissionService.updateParentsDetails(data);
  }
);

export const updateEducationAndProfessionDetails = createAsyncThunk(
  "admission/updateEducationAndProfessionDetails",
  async (data: IEducationAndProfessionDetailsData) => {
    return admissionService.updateEducationAndProfessionDetails(data);
  }
);

export const updateDocuments = createAsyncThunk(
  "admission/updateDocuments",
  async (data: IDocumentsData) => {
    return admissionService.updateDocuments(data);
  }
);

export const deleteAdmissionOtherDocumentById = createAsyncThunk(
  "admission/admission-other-documents",
  async (id: number) => {
    return admissionService.deleteAdmissionOtherDocumentById(id);
  }
);

export const installmentModify = createAsyncThunk(
  "admission/installmentModify",
  async (data: IInstallmentModify) => {
    return admissionService.installmentModify(data);
  }
);

export const searchUnAssignBatchAdmission = createAsyncThunk(
  "admission/searchAnAssignBatchAdmission",
  async (data: IAdmission | URLSearchParams | {}) => {
    return admissionService.searchUnAssignBatchAdmission(data);
  }
);

export const missingCRMDetails = createAsyncThunk(
  "admission/missingCRMDetails",
  async (data: IAdmission | URLSearchParams | {}) => {
    return admissionService.missingCRMDetails(data);
  }
);

export const concessionApplyUpdate = createAsyncThunk(
  "admission/concessionApplyUpdate",
  async (data: IConcessionDetails) => {
    return admissionService.concessionApplyUpdate(data);
  }
);

export const verifiyCRMRecord = createAsyncThunk(
  "admission/verifiyCRMRecord",
  async (data: {admission_id: number}) => {
    return admissionService.verifiyCRMRecord(data);
  }
);

export const crmLeadHistoryRecord = createAsyncThunk(
  "admission/crmLeadHistoryRecord",
  async (admission_id: number) => {
    return admissionService.crmLeadHistoryRecord(admission_id);
  }
);

export const removeEnquirieById = createAsyncThunk(
  "admission/removeCRMLeadEnquirieById",
  async (id: number) => {
    return admissionService.deleteCRMLeadEnquirie(id);
  }
);

export const concessionRemove = createAsyncThunk(
  "admission/concessionRemove",
  async (data: IConcessionDetails) => {
    return admissionService.concessionRemove(data);
  }
);

export const createPenalty = createAsyncThunk(
  "admission/createPenalty",
  async (data: IAddPenaltyFormValues | {}) => {
    return admissionService.createPenalty(data);
  }
);

export const searchPenaltyData = createAsyncThunk(
  "admission/searchPenaltyData",
  async (data: IPenaltyFilter | URLSearchParams | {}) => {
    return admissionService.searchPenaltyData(data);
  }
);
export const autoFeedback= createAsyncThunk(
  "admission/autoFeedback",
  async (data: IAutoFeedbackData | {}) => {
    return admissionService.autoFeedback(data);
  }
);

