import { Button, Card, Col, Form, Input, Row, Tabs } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useEffect, useMemo, useState } from "react";
import { DoubleRightOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import CustomForm from "src/components/CustomForm";
import FloatLabel from "src/components/Form/FloatLabel";
import { ISubCourseTopicDetails } from "src/services/subCourseTopic/subCourseTopic.model";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchSubCourseTopicData } from "src/state/subCourseTopic/subCourseTopic.action";
import { subCourseTopicSelector } from "src/state/subCourseTopic/subCourseTopic.reducer";
import { GetSortOrderWithoutLowercase } from "src/utils/helperFunction";
import {
  ISubCourseTemplateAddProps,
  TemplateShiningSheetTopic,
  ISubtopics,
  ICreateTempPayload,
  ITemplateAndSubCourseTopicDataPayload,
} from "../SubCourseTemplate.model";
import ViewTopicsAndSubTopics from "../ViewTopicsAndSubTopics";
import DraggableList from "src/components/DragAndDropList";
import TabPane from "antd/lib/tabs/TabPane";
import { SubCourseTopicTabType } from "src/utils/constants/constant";
import { SubTopic } from "src/services/templateSigningSheet/templateSigningSheet.model";

const TAB_DATA = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  NEW: "NEW",
};

const SubCourseTemplateAdd = (props: ISubCourseTemplateAddProps) => {
  const { data, onSubmit, myRef, setDisable } = props;
  const dispatch = useDispatch<AppDispatch>();
  const subCourseTopicState = useAppSelector(subCourseTopicSelector);
  const [form] = Form.useForm();
  const { subcourse_id } = useParams();

  const [selectAllChecked, setSelectAllChecked] = useState({
    [TAB_DATA.LEFT]: false,
    [TAB_DATA.RIGHT]: false,
    [TAB_DATA.NEW]: false,
  });
  const rules = {
    template_name: [{ required: true, message: "Please Enter Template name" }],
    description: [{ required: true, message: "Please Enter Description" }],
  };
  const [topicTabIndexNo, setTopicTabIndexNo] = useState<string>(
    SubCourseTopicTabType.TEMPLATED_TOPIC
  );

  // main templated topic data
  const [templatedTopics, setTemplatedTopics] = useState<
    ISubCourseTopicDetails[]
  >([]);

  // Subcourse Topic tab data
  const [subCourseTopics, setSubCourseTopics] = useState<
    TemplateShiningSheetTopic[]
  >([]);

  // Edit time all topic data get and set
  useEffect(() => {
    if (subcourse_id) {
      const searchSubCourseTopicDataPayload = {
        subcourse_id: subcourse_id,
        noLimit: true,
      };

      dispatch(searchSubCourseTopicData(searchSubCourseTopicDataPayload)).then(
        (res: any) => {
          if (res.payload) {
            if (res.payload?.data?.rows) {
              const newSubCourseTopicData = res.payload?.data?.rows
                ?.filter(
                  (topic: ISubCourseTopicDetails) => topic.status === true
                )
                .map((topic: ISubCourseTopicDetails) => {
                  return {
                    ...topic,
                    selected: false,
                    subTopics: topic?.subTopics
                      ?.filter(
                        (subTopicDetails: SubTopic) =>
                          subTopicDetails.status === true
                      )
                      ?.sort(GetSortOrderWithoutLowercase("sequence", "ASC"))
                      ?.map((subTopics) => {
                        return { ...subTopics, selected: false };
                      }),
                  };
                });
              setSubCourseTopics(newSubCourseTopicData);
            }
          }
        }
      );
    }
  }, [subcourse_id]);

  // first time all topic data get and set
  useEffect(() => {
    if (subCourseTopicState.subCourseTopicData.data && !data) {
      const newSubCourseTopicData =
        subCourseTopicState.subCourseTopicData.data.rows
          .filter((topic: ISubCourseTopicDetails) => topic.status === true)
          .map((topic: ISubCourseTopicDetails) => {
            return {
              ...topic,
              status: false,
              selected: false,
              subTopics: topic.subTopics
                ?.filter(
                  (subTopicDetails: SubTopic) => subTopicDetails.status === true
                )
                ?.sort(GetSortOrderWithoutLowercase("sequence", "ASC"))
                ?.map((subTopic: SubTopic) => {
                  return {
                    ...subTopic,
                    status: false,
                    selected: false,
                  };
                }),
            };
          });
      setTemplatedTopics(newSubCourseTopicData);
    }
  }, [subCourseTopicState.subCourseTopicData.data]);
  // Any Checkbox change
  const onChangeCheckBox = (event: CheckboxChangeEvent) => {
    const modifyTopics = (
      topics: ISubCourseTopicDetails[] | TemplateShiningSheetTopic[]
    ) => {
      return topics.map((topic) => {
        if (
          topic.id === event.target.value.id &&
          event.target.value.sub_topic !== null
        ) {
          return {
            ...topic,
            subTopics: topic.subTopics?.map((subTopic) => {
              return {
                ...subTopic,
                selected:
                  subTopic.id == event.target.value.subTopic
                    ? event.target.checked
                    : subTopic.selected,
              };
            }),
          };
        } else if (
          event.target.value.subTopic.length > 0 &&
          topic.id === event.target.value.topic
        ) {
          return {
            ...topic,
            subTopics: topic.subTopics?.map((subTopic) => {
              return {
                ...subTopic,
                selected: event.target.value.subTopic.includes(subTopic.id)
                  ? event.target.checked
                  : subTopic.selected,
              };
            }),
          };
        }
        return topic;
      });
    };

    // main data in change selected value
    const modifySubCourseTopicData = modifyTopics(
      templatedTopics
    ) as ISubCourseTopicDetails[];
    setTemplatedTopics(modifySubCourseTopicData);

    // Subcourse Topic tab data in change selected value
    let modifySubCourseTemplateTopicData = modifyTopics(
      subCourseTopics
    ) as TemplateShiningSheetTopic[];

    // Subcourse Topic tab data in change selected value ( - all subtopics selected then parent topic selected)
    modifySubCourseTemplateTopicData = modifySubCourseTemplateTopicData.map(
      (topic) => {
        return {
          ...topic,
          selected:
            topic.subTopics?.filter((subTopic) => subTopic.selected === true)
              .length == topic.subTopics?.length,
        };
      }
    );

    setSubCourseTopics(modifySubCourseTemplateTopicData);
  };

  // right tab data sequence change
  const onRightTabSequenceChange = (value: TemplateShiningSheetTopic[]) => {
    const data = value.map((sheetTopic, index) => ({
      ...sheetTopic,
      sequence: index + 1,
      subTopics: sheetTopic.subTopics?.map((subTopic, i) => ({
        ...subTopic,
        sequence: i + 1,
      })),
    }));

    const modifySubCourseTemplateTopicData = templatedTopics
      .map((topic) => {
        return {
          ...topic,
          sequence: data.find((item) => item.id === topic.id)?.sequence || 0,
          subTopics: topic.subTopics
            ?.map((subTopic) => ({
              ...subTopic,
              sequence:
                data
                  .find((item) => item.id === topic.id)
                  ?.subTopics?.find((item) => item.id === subTopic.id)
                  ?.sequence || 0,
            }))
            .sort(GetSortOrderWithoutLowercase("sequence", "ASC")),
        };
      })
      .sort(
        GetSortOrderWithoutLowercase("sequence", "ASC")
      ) as unknown as ISubCourseTopicDetails[];

    setTemplatedTopics(modifySubCourseTemplateTopicData);
  };

  //  Edit time first time set data
  useEffect(() => {
    if (data) {
      const falseValue = data.templateShiningSheetTopics;
      if (falseValue) {
        const allValue: ISubCourseTopicDetails[] =
          data.templateShiningSheetTopics as unknown as ISubCourseTopicDetails[];
        if (allValue) {
          const finalValue = [];
          for (let i = 0; i < allValue.length; i++) {
            let element = allValue[i];
            element = {
              ...element,
              subTopics: [],
            };
            falseValue.forEach((topicDetails) => {
              if (element.id === topicDetails.parent_id) {
                if (element.subTopics === undefined) {
                  element = {
                    ...element,
                    selected: false,
                    subTopics: [topicDetails],
                  };
                } else {
                  element.subTopics.push(topicDetails);
                }
              }
            });
            finalValue.push(element);
          }
          const finaldataMap = finalValue
            .map((topicDetails) => {
              if (
                topicDetails.type !== "LECTURE" &&
                topicDetails.type !== null
              ) {
                return topicDetails;
              } else {
                if (
                  topicDetails.subTopics &&
                  topicDetails.subTopics.length > 0
                ) {
                  return topicDetails;
                }
              }
            })
            .filter((topicDetails) => topicDetails);
          const newFinalValue = finaldataMap.map((topicDetails) => {
            return {
              ...topicDetails,
              sequence: Number(topicDetails?.sequence),
              selected: false,
              subTopics:
                topicDetails?.subTopics &&
                topicDetails?.subTopics
                  .map((subTopicDetails) => {
                    return {
                      ...subTopicDetails,
                      selected: false,
                    };
                  })
                  .sort(GetSortOrderWithoutLowercase("sequence", "ASC")),
            };
          });
          const valueSort = [...newFinalValue].sort(
            GetSortOrderWithoutLowercase("sequence", "ASC")
          ) as unknown as ISubCourseTopicDetails[];
          setTemplatedTopics(valueSort);
        }
      }
    }
  }, [data]);

  // move data in left to right
  const moveToRightTabData = () => {
    setDisable(true);

    const modifySubCourseTopicData = templatedTopics.map(
      (topicDetails: ISubCourseTopicDetails, index) => {
        const subTopics = topicDetails.subTopics?.map((subTopicDetails) => ({
          ...subTopicDetails,
          selected: false,
          status: subTopicDetails.selected ? true : subTopicDetails.status,
        }));

        return {
          ...topicDetails,
          selected: false,
          subTopics: subTopics,
        };
      }
    );

    const { rightSide } = displayTopicData;

    setTemplatedTopics(
      modifySubCourseTopicData.map((topic, index) => {
        if (rightSide.length > 0) {
          const checkTopic = rightSide.find((item) => item.id === topic.id);
          if (checkTopic) {
            return {
              ...topic,
              sequence: checkTopic.sequence,
            };
          }
          return {
            ...topic,
            sequence: rightSide[rightSide.length - 1].sequence + 1,
          };
        } else {
          return {
            ...topic,
            sequence: index + 1,
          };
        }
      })
    );
    setSelectAllChecked({
      [TAB_DATA.LEFT]: false,
    });

    if (topicTabIndexNo === SubCourseTopicTabType.SUBCOURSE_TOPIC) {
      const modifySubCourseTemplateTopicData = subCourseTopics
        .map((topicDetails) => {
          return {
            ...topicDetails,
            subTopics: topicDetails.subTopics?.filter((subTopicDetails) => {
              return subTopicDetails.selected;
            }),
          };
        })
        .filter((topicDetails) => (topicDetails?.subTopics || [])?.length > 0);

      const updateSubCourseTemplateTopicData = subCourseTopics
        .map((topicDetails) => {
          return {
            ...topicDetails,
            subTopics: topicDetails.subTopics?.filter((subTopicDetails) => {
              return !subTopicDetails.selected;
            }),
          };
        })
        .filter((topicDetails) => (topicDetails?.subTopics || [])?.length > 0);

      setSubCourseTopics(updateSubCourseTemplateTopicData);

      const allExitSubtopic = modifySubCourseTopicData.flatMap(
        (topicDetails: ISubCourseTopicDetails) => {
          return (
            topicDetails.subTopics?.map((subTopic) => ({
              ...subTopic,
              parentName: topicDetails.name,
            })) || []
          );
        }
      );

      // new data in old data only status change
      const firstAdd: ISubtopics[] = [];
      const modifySubCourseTopicDataWithNewTopic = modifySubCourseTopicData.map(
        (topicDetails: ISubCourseTopicDetails) => {
          const sameName = modifySubCourseTemplateTopicData.filter(
            (topic) => topic.name == topicDetails.name
          );
          if (sameName) {
            const modifySubTopics = topicDetails?.subTopics?.map((subTopic) => {
              const checkSubTopic = sameName
                ?.flatMap((sub) => sub.subTopics)
                .find((item) => item?.description == subTopic?.description);
              if (checkSubTopic) {
                return {
                  ...subTopic,
                  status: true,
                };
              }
              return subTopic;
            });

            const checkTheSubTopic = sameName
              ?.flatMap((sub) => sub.subTopics)
              .filter(
                (subTopic) =>
                  !allExitSubtopic.some(
                    (item) =>
                      item?.description == subTopic?.description &&
                      item?.parentName == topicDetails.name
                  )
              )
              .filter(
                (subTopic) =>
                  !modifySubTopics?.some(
                    (item) => item?.description == subTopic?.description
                  )
              )
              .map((subTopic) => {
                return {
                  ...subTopic,
                  id: 0,
                  selected: false,
                  status: true,
                };
              })
              .filter(
                (item) =>
                  !firstAdd?.some((sub) => sub.description == item.description)
              );

            firstAdd.push(...(checkTheSubTopic as ISubtopics[]));
            return {
              ...topicDetails,
              subTopics: [
                ...(modifySubTopics || []),
                ...(checkTheSubTopic || []),
              ],
            };
          }
          return {
            ...topicDetails,
          };
        }
      ) as unknown as ISubCourseTopicDetails[];

      modifySubCourseTemplateTopicData.forEach((topicDetails) => {
        const sameName: ISubCourseTopicDetails | undefined =
          modifySubCourseTopicDataWithNewTopic.find(
            (subTopic: ISubCourseTopicDetails) =>
              subTopic.name == topicDetails.name
          );
        if (!sameName) {
          modifySubCourseTopicDataWithNewTopic.push({
            ...topicDetails,
            id: 0,
            selected: false,
            subTopics: topicDetails?.subTopics?.map((subItem, key) => ({
              ...subItem,
              selected: false,
              status: true,
              id: 0,
            })),
          } as unknown as ISubCourseTopicDetails);
        }
      });

      setTemplatedTopics(modifySubCourseTopicDataWithNewTopic);

      setSelectAllChecked({
        [TAB_DATA.NEW]: false,
      });
    }
  };

  // move data in right to left
  const moveToLeftTabData = () => {
    setDisable(true);
    const modifySubCourseTemplateTopicData = templatedTopics
      .map((topicDetails: ISubCourseTopicDetails) => {
        return {
          ...topicDetails,
          selected: false,
          subTopics: topicDetails.subTopics
            ?.map((subTopicDetails) => {
              if (subTopicDetails.id == 0 && subTopicDetails.selected) {
                return {
                  ...subTopicDetails,
                  status: subTopicDetails.selected
                    ? false
                    : subTopicDetails.status,
                  selected: false,
                };
              }
              return null;
            })
            .filter(Boolean) as unknown as ISubtopics[],
        };
      })
      .filter((topicDetails) => (topicDetails?.subTopics || [])?.length > 0);

    const firstAdd: ISubtopics[] = [];
    const updateSubCourseTemplateTopicData: TemplateShiningSheetTopic[] =
      subCourseTopics.map((topicDetails: TemplateShiningSheetTopic) => {
        const sameName = modifySubCourseTemplateTopicData.filter(
          (topic) => topic.name == topicDetails.name
        );
        if (sameName) {
          const checkTheSubTopic = sameName
            ?.flatMap((sub) => sub.subTopics)
            .filter(
              (subTopic) =>
                !(topicDetails?.subTopics || []).some(
                  (item) => item.description == subTopic?.description
                )
            )
            .map((subTopic) => {
              return {
                ...subTopic,
                selected: false,
                status: false,
              };
            })
            .filter(
              (item) =>
                !firstAdd?.some((sub) => sub.description == item.description)
            );

          firstAdd.push(...checkTheSubTopic);

          const modifySubTopics = (topicDetails?.subTopics || []).map(
            (subTopic) => {
              const checkSubTopic = sameName
                ?.flatMap((sub) => sub.subTopics)
                .find((item) => item.description == subTopic?.description);
              if (checkSubTopic) {
                return {
                  ...subTopic,
                  status: false,
                };
              }
              return subTopic;
            }
          );
          return {
            ...topicDetails,
            subTopics: [...modifySubTopics, ...(checkTheSubTopic || [])],
          };
        } else {
          return {
            ...topicDetails,
          };
        }
      }) as unknown as TemplateShiningSheetTopic[];

    const sameName: TemplateShiningSheetTopic[] =
      modifySubCourseTemplateTopicData.filter(
        (topic) =>
          !subCourseTopics.some((subTopic) => subTopic.name == topic.name)
      ) as unknown as TemplateShiningSheetTopic[];

    if (sameName && sameName.length > 0) {
      updateSubCourseTemplateTopicData.push(...sameName);
    }

    setSubCourseTopics(updateSubCourseTemplateTopicData);

    const modifySubCourseTopicData = templatedTopics
      .map((topicDetails: ISubCourseTopicDetails) => {
        return {
          ...topicDetails,
          selected: false,
          subTopics: topicDetails.subTopics
            ?.map((subTopicDetails) => {
              if (subTopicDetails.id == 0 && subTopicDetails.selected) {
                return null;
              }
              return {
                ...subTopicDetails,
                selected: false,
                status: subTopicDetails.selected
                  ? false
                  : subTopicDetails.status,
              };
            })
            .filter(Boolean),
        };
      })
      .filter(
        (topicDetails) => (topicDetails?.subTopics || [])?.length > 0
      ) as unknown as ISubCourseTopicDetails[];

    setTemplatedTopics(modifySubCourseTopicData);

    setSelectAllChecked({
      [TAB_DATA.RIGHT]: false,
    });
  };

  // select all checkbox tab
  const handleSelectAllChange = (event: CheckboxChangeEvent, tab: string) => {
    const updateTopics = (prev: ISubCourseTopicDetails[], status: boolean) => {
      return prev.map((topic) => {
        return {
          ...topic,
          subTopics: topic?.subTopics?.map((subTopic) => {
            return {
              ...subTopic,
              selected:
                subTopic.status == status
                  ? event.target.checked
                  : subTopic.selected,
            };
          }),
        };
      });
    };

    const updateSelectAllChecked = (
      prev: { [x: string]: boolean },
      tab: string
    ) => {
      return {
        ...prev,
        [tab]: event.target.checked,
      };
    };

    if (tab == TAB_DATA.LEFT) {
      setTemplatedTopics((prev: ISubCourseTopicDetails[]) =>
        updateTopics(prev, false)
      );
      setSelectAllChecked((prev) =>
        updateSelectAllChecked(prev, TAB_DATA.LEFT)
      );
    } else if (tab == TAB_DATA.RIGHT) {
      setTemplatedTopics((prev: ISubCourseTopicDetails[]) =>
        updateTopics(prev, true)
      );
      setSelectAllChecked((prev) =>
        updateSelectAllChecked(prev, TAB_DATA.RIGHT)
      );
    } else if (tab == TAB_DATA.NEW) {
      setSubCourseTopics((prev) => {
        return prev.map((topic) => {
          return {
            ...topic,
            selected: event.target.checked,
            subTopics: topic?.subTopics?.map((subTopic: ISubtopics) => {
              return {
                ...subTopic,
                selected: event.target.checked,
              };
            }),
          };
        });
      });
      setSelectAllChecked((prev) => updateSelectAllChecked(prev, TAB_DATA.NEW));
    }
  };

  // tab change
  const onTabChange = (value: string) => {
    setTopicTabIndexNo(value);
  };

  // display data in left and right tab
  const displayTopicData = useMemo(() => {
    const filterSubTopics = (
      topic: ISubCourseTopicDetails,
      status: boolean
    ) => {
      const filteredSubTopics = topic.subTopics?.filter(
        (subTopic: SubTopic) => subTopic.status === status
      );
      const selected =
        filteredSubTopics?.filter((subTopic) => subTopic.selected).length ===
        filteredSubTopics?.length;
      return {
        ...topic,
        selected,
        subTopics: filteredSubTopics,
      };
    };

    const leftSide = templatedTopics
      .map((topic: ISubCourseTopicDetails) => filterSubTopics(topic, false))
      .filter((topic) => (topic?.subTopics || [])?.length > 0)
      .sort(GetSortOrderWithoutLowercase("sequence", "ASC"));

    const rightSide = templatedTopics
      .map((topic: ISubCourseTopicDetails) => filterSubTopics(topic, true))
      .filter((topic) => (topic?.subTopics || [])?.length > 0)
      .sort(GetSortOrderWithoutLowercase("sequence", "ASC"));

    console.log("rightSide :>> ", rightSide);

    return {
      leftSide,
      rightSide,
    };
  }, [templatedTopics]);

  const topicDuration = (topicDetails: ISubCourseTopicDetails) => {
    let duration = 0;
    topicDetails?.subTopics?.map((topic) => {
      if (topic.status === true) {
        duration += 1;
      }
    });
    return duration;
  };

  // submit data
  const onFinish = (values: ICreateTempPayload) => {
    const isEdit = data ? true : false;
    let finalTemplateShiningSheetTopics: ISubCourseTopicDetails[] =
      templatedTopics;
    if (!isEdit) {
      finalTemplateShiningSheetTopics = finalTemplateShiningSheetTopics.map(
        (topicDetails: ISubCourseTopicDetails) => {
          return {
            id: 0,
            name: topicDetails.name,
            description: topicDetails.description,
            sequence: topicDetails.sequence,
            type: topicDetails.type,
            duration: topicDuration(topicDetails),
            marks: topicDetails?.marks,
            status:
              (topicDetails?.subTopics || []).filter((item) => item.status)
                .length > 0,
            sub_topics: topicDetails.subTopics?.map((subTopicDetails) => {
              return {
                id: 0,
                description: subTopicDetails.description,
                status: subTopicDetails.status,
                sequence: subTopicDetails.sequence,
              };
            }),
          };
        }
      ) as unknown as ISubCourseTopicDetails[];
    } else {
      finalTemplateShiningSheetTopics = finalTemplateShiningSheetTopics.map(
        (topicDetails: ISubCourseTopicDetails) => {
          return {
            id: topicDetails.id,
            name: topicDetails.name,
            description: topicDetails.description,
            sequence: topicDetails.sequence,
            type: topicDetails.type,
            duration: topicDuration(topicDetails) || null,
            status:
              (topicDetails?.subTopics || []).filter((item) => item.status)
                .length > 0,
            marks: topicDetails?.marks,
            sub_topics: topicDetails.subTopics?.map((subTopicDetails) => {
              return {
                id: subTopicDetails.id,
                description: subTopicDetails.description,
                status: subTopicDetails.status,
                sequence: subTopicDetails.sequence,
              };
            }),
          };
        }
      ) as unknown as ISubCourseTopicDetails[];
    }

    Object.assign(values, {
      subcourse_id: subcourse_id,
      template_shining_sheet_topics: finalTemplateShiningSheetTopics,
    });

    onSubmit(values);
  };

  return (
    <>
      <CustomForm
        className="drawer-form"
        name="basic"
        form={form}
        initialValues={data}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        requiredMark={true}
      >
        <>
          <Row gutter={10} className="gx-my-2">
            <Col xs={12} className="gx-mb-1">
              <FloatLabel
                label="Template name"
                placeholder="Enter Template name"
                name="name"
                required
              >
                <Form.Item name="name" rules={rules.template_name}>
                  <Input size="large" />
                </Form.Item>
              </FloatLabel>
            </Col>
            <Col xs={12} className="gx-mb-1">
              <FloatLabel
                label="Description"
                placeholder="Enter Description"
                name="description"
                required
              >
                <Form.Item name="description" rules={rules.description}>
                  <Input size="large" />
                </Form.Item>
              </FloatLabel>
            </Col>
          </Row>
          <Row>
            <Col span={24} md={11}>
              <div className="view-topic-scroll">
                <Card className="table-card rnw-card gx-mb-0">
                  <Tabs
                    onChange={onTabChange}
                    type="card"
                    defaultActiveKey={topicTabIndexNo}
                  >
                    <TabPane
                      tab="Templated Topic "
                      key={SubCourseTopicTabType.TEMPLATED_TOPIC}
                    >
                      <Row>
                        <Col span={24}>
                          <Card className="table-card rnw-card transfer-addmission">
                            <Col span={24}>
                              <Checkbox
                                className="gx-py-2 gx-w-100"
                                style={{
                                  fontWeight: 500,
                                  borderBottom: "1px solid #e8e8e8",
                                }}
                                onChange={(event) => {
                                  handleSelectAllChange(event, TAB_DATA.LEFT);
                                }}
                                checked={selectAllChecked[TAB_DATA.LEFT]}
                              >
                                Select All
                              </Checkbox>

                              <ViewTopicsAndSubTopics
                                data={
                                  displayTopicData.leftSide &&
                                  displayTopicData.leftSide
                                }
                                onchange={(event: CheckboxChangeEvent) => {
                                  onChangeCheckBox(event);
                                }}
                              />
                            </Col>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    {data && (
                      <TabPane
                        tab="Subcourse Topic"
                        key={SubCourseTopicTabType.SUBCOURSE_TOPIC}
                      >
                        <Row>
                          <Col span={24}>
                            <Card className="table-card rnw-card transfer-addmission">
                              <Col span={24}>
                                <Checkbox
                                  className="gx-py-2 gx-w-100"
                                  style={{
                                    fontWeight: 500,
                                    borderBottom: "1px solid #e8e8e8",
                                  }}
                                  onChange={(event) => {
                                    handleSelectAllChange(event, TAB_DATA.NEW);
                                  }}
                                  checked={selectAllChecked[TAB_DATA.NEW]}
                                >
                                  Select All
                                </Checkbox>
                                <ViewTopicsAndSubTopics
                                  data={
                                    subCourseTopics &&
                                    (subCourseTopics as ISubCourseTopicDetails[])
                                  }
                                  onchange={(e: CheckboxChangeEvent) =>
                                    onChangeCheckBox(e)
                                  }
                                />
                              </Col>
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    )}
                  </Tabs>
                </Card>
              </div>
            </Col>
            <Col
              span={24}
              md={2}
              className="direction-btn gx-d-flex gx-align-items-center gx-justify-content-center gx-flex-column"
            >
              <div className="gx-double-right-icon-wrapper gx-mb-2">
                <a onClick={moveToRightTabData}>
                  <DoubleRightOutlined className="gx-double-right-icon" />
                </a>
              </div>
              <div className="gx-double-right-icon-wrapper">
                <a onClick={moveToLeftTabData}>
                  <DoubleLeftOutlined className="gx-double-right-icon" />
                </a>
              </div>
            </Col>
            <Col span={24} md={11}>
              <div className="view-topic-scroll">
                <Col span={24}>
                  <Checkbox
                    className="gx-py-2 gx-w-100"
                    style={{
                      fontWeight: 500,
                      borderBottom: "1px solid #e8e8e8",
                    }}
                    onChange={(event) => {
                      handleSelectAllChange(event, TAB_DATA.RIGHT);
                    }}
                    checked={selectAllChecked[TAB_DATA.RIGHT]}
                  >
                    Select All
                  </Checkbox>
                  <DraggableList
                    value={
                      displayTopicData.rightSide &&
                      (displayTopicData.rightSide as unknown as ISubCourseTopicDetails[])
                    }
                    onchange={(event: CheckboxChangeEvent) => {
                      onChangeCheckBox(event);
                    }}
                    changeValue={(event: TemplateShiningSheetTopic[]) => {
                      onRightTabSequenceChange(event);
                    }}
                  />
                </Col>
              </div>
            </Col>
          </Row>
          <Row style={{ display: "none" }}>
            <div className="gx-mb-2 gx-mb-sm-3 gx-mb-lg-4">
              <Form.Item className="modal-btn-grp">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-submit"
                  ref={myRef}
                >
                  {data ? "Update" : "Submit"}
                </Button>
              </Form.Item>
            </div>
          </Row>
        </>
      </CustomForm>
    </>
  );
};

export default SubCourseTemplateAdd;
