import { Button, Col, Row, Form, Card, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ability } from 'src/ability';
import { IExamPaperList, IExamPaperQuestion, IFormValues } from 'src/services/ExamBatch/ExamBatch.model';
import { useAppSelector } from 'src/state/app.hooks';
import { AppDispatch } from 'src/state/app.model';
import { addMarkById, examPaperList, examPaperQuestion, } from 'src/state/ExamBatch/exambatch.action';
import { examBatchSelector, clearRemoveMessage } from 'src/state/ExamBatch/exambatch.reducer';
import { Common } from 'src/utils/constants/constant';
import { bufferURLDecode } from 'src/utils/helperFunction';

const ExamAnswerSheet = () => {
    const { batch_id, exam_paper_id, exam_student_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const batchExamtState = useAppSelector(examBatchSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const [form] = Form.useForm();

    const [studentData, setStudentData] = useState<IExamPaperList>();

    useEffect(() => {
        if (batchExamtState?.examPaperList?.data.length == 0) {
            dispatch(examPaperList(Number(exam_paper_id))).then((res: any) => {
                if (res.payload.data) {
                    const data = res.payload.data.find((data: IExamPaperList) => data.id === Number(exam_student_id))
                    setStudentData(data);
                }
            })
        } else {
            const data = batchExamtState?.examPaperList?.data.find((data: IExamPaperList) => data.id === Number(exam_student_id))
            setStudentData(data);
        }
    }, [])

    useEffect(() => {
        dispatch(examPaperQuestion(Number(exam_student_id)))
    }, [])

    useEffect(() => {
        if (batchExamtState.addMarkById.message) {
            if (batchExamtState.addMarkById.hasErrors) {
                message.error(batchExamtState.addMarkById.message);
            } else {
                message.success(batchExamtState.addMarkById.message);
            }
            dispatch(clearRemoveMessage());
        }
    }, [batchExamtState.addMarkById.message]);

    const isButtonShowed = () => {
        const rows = batchExamtState.examPaperQuestion.data;
        const allMCQ = rows.every((row: IExamPaperQuestion) => row.question_type === 'MCQ');
        const allSubjective = rows.every((row: IExamPaperQuestion) => row.question_type === 'SUBJECTIVE');
        const anyNonEmptySubjectiveAnswers = rows
            .filter((row: IExamPaperQuestion) => row.question_type === 'SUBJECTIVE')
            .some((row) => {
                if (row.examPaperStudentAnswers?.length === 0) {
                    return true;
                }
                return row?.examPaperStudentAnswers && row?.examPaperStudentAnswers[0]?.marks === null
            });
        if (allMCQ) {
            return false;
        }
        if (allSubjective) {
            return anyNonEmptySubjectiveAnswers;
        }
        return false;
    };

    const transformFormValuesToPayload = (values: any) => {
        return {
            students: values.rows.map((item: any, index: number) => {
                if (item) {
                    return {
                        id: batchExamtState.examPaperQuestion.data[index].id,
                        marks: item.marks
                    };
                }
                return null;
            }).filter((item: any) => item !== null)
        };
    };

    const handleFinish = (values: IFormValues) => {
        const payload = transformFormValuesToPayload(values);
        dispatch(addMarkById({ exam_student_id: Number(exam_student_id), payload })).then((res) => {
            if (res) {
                dispatch(examPaperQuestion(Number(exam_student_id)))
            }
        })
    };

    const handleBack = () => {
        const url = searchParams.get("r");
        if (url) {
            const backURL = bufferURLDecode(url);
            navigate(backURL);
        } else {
            const currentPath = window.location.pathname;
            const segments = currentPath.split('/');
            const targetSegment = segments[1];
            if (targetSegment === "batch") {
                navigate("/batch");
            } else {
                navigate("/my-team-batches");
            }
        }
    };

    return (
        <>
            <div className="rnw-main-content">
                <Row align="middle" justify="space-between" gutter={24} className="mb-20">
                    <Col xs={17} sm={16}>
                        <h2 className="rnw-page-title gx-d-flex">
                            {studentData && studentData.admission?.first_name + " " + studentData.admission?.middle_name + " " + studentData.admission?.last_name}
                        </h2>
                        <span>{`Gr id: ${studentData && studentData?.admission?.gr_id}`}</span>
                    </Col>
                    <Col xs={7} sm={8} className="text-align-right">
                        <Button type="primary" onClick={handleBack}>
                            <i className="fa fa-arrow-left back-icon"></i>{" "}
                            <span className="gx-d-none gx-d-sm-inline-block">Back</span>
                        </Button>
                        {ability.can(
                            Common.Actions.CAN_UPDATE,
                            Common.Modules.ACADEMIC.ACADEMIC_EXAM
                            ) && (

                                    isButtonShowed() && (
                                        <Button type="primary" htmlType="submit" form="examForm">
                                            <span className="gx-d-none gx-d-sm-inline-block">Save</span>
                                        </Button>
                                    )
                        )}
                        </Col>
                </Row>
                <Form
                    id="examForm"
                    name="examForm"
                    form={form}
                    className="drawer-form"
                    onFinish={handleFinish}
                    initialValues={{
                        rows: batchExamtState.examPaperQuestion.data.map((item: any) => {
                            let obtainedMarks = null;
                            if (item.examPaperStudentAnswers.length > 0) {
                                obtainedMarks = item.examPaperStudentAnswers[0].marks;
                            }
                            return {
                                marks: obtainedMarks
                            };
                        })
                    }}
                >
                    <Card className="table-card signingsheet-card">
                        <table className="faculty-signing" style={{ minWidth: "800px" }}>
                            <thead>
                                <tr>
                                    <th style={{ width: "3%" }}>No.</th>
                                    <th style={{ width: "500px" }}>Question</th>
                                    <th style={{ width: "30px" }}>Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {batchExamtState.examPaperQuestion.data &&
                                    batchExamtState.examPaperQuestion.data.map((data: any, rowIndex: any) => {

                                        let obtainedMarks = null;
                                        if (data.examPaperStudentAnswers.length > 0) {
                                            obtainedMarks = data.examPaperStudentAnswers[0].marks;
                                        }
                                        const totalMarks = data.marks;

                                        return (
                                            <tr key={data.id}>
                                                <td align="center">
                                                    <span style={{ display: "inline-block" }}>
                                                        {rowIndex + 1}
                                                    </span>
                                                </td>
                                                <td>
                                                    <div>
                                                        <h5 style={{ margin: 0 }}>{`${data.question} (${data.question_type})`}</h5>
                                                        {data.question_type === "MCQ" && (
                                                            <ul style={{ paddingLeft: '10px', margin: "10px 0", listStyleType: 'none' }}>
                                                                {data.examPaperQuestionAnswers?.map((answer: any, index: number) => (
                                                                    <li key={answer.id} style={{ marginBottom: '5px' }}>
                                                                        <span>{index + 1}. </span>{answer.option}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        <div style={{ marginTop: '10px' }}>
                                                            <p style={{ margin: 0 }}>
                                                                <strong>Answer: </strong>
                                                                {data.examPaperStudentAnswers.length > 0 ? (
                                                                    data.examPaperStudentAnswers.map((studentAnswer: any, index: number) => {
                                                                        const mcqAnswer = data.examPaperQuestionAnswers.find(
                                                                            (examPaperQuestionAnswer: any) => examPaperQuestionAnswer.id === studentAnswer.exam_paper_question_answer_id
                                                                        );

                                                                        const answerContent = data.question_type === "MCQ"
                                                                            ? (mcqAnswer?.option || "No Answer")
                                                                            : (studentAnswer.answer || "No Answer");

                                                                        return (
                                                                            <span key={index}>
                                                                                {answerContent}
                                                                            </span>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <span>No Answer</span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td align='center' style={{ textAlign: 'center' }}>
                                                    <div className="marks gx-align-items-center" style={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        {data.question_type === "SUBJECTIVE" && obtainedMarks === null ? (
                                                            <Form.Item
                                                                name={['rows', rowIndex, 'marks']}
                                                                initialValue={obtainedMarks}
                                                                style={{ margin: 0 }}
                                                                className='gx-align-items-center'
                                                            >
                                                                <Input
                                                                    type="number"
                                                                    min={0}
                                                                    max={totalMarks}
                                                                    disabled={false}
                                                                />
                                                            </Form.Item>
                                                        ) : (
                                                            <span className='gx-align-items-center'>{obtainedMarks != null ? obtainedMarks : '-'}</span>
                                                        )}
                                                        / {totalMarks}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </Card>
                </Form>
            </div>
        </>
    );
};

export default ExamAnswerSheet;
