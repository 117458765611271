import {
  Col,
  Input,
  Row,
  Form,
  Select,
  DatePicker,
  Card,
  Button,
  message,
  Radio,
  Typography,
} from "antd";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ColumnsType } from "antd/lib/table";
import TableComponent from "src/components/DataTable";
import FloatLabel from "src/components/Form/FloatLabel";
import { ISaveExpertSessionPayload } from "src/services/students/student.model";
import { useAppSelector } from "src/state/app.hooks";
import {
  clearRemoveMessage,
  studentsSelector,
} from "src/state/students/students.reducer";
import StudentMobileNumber from "src/components/StudentMobileNumber";

import { userSelector } from "src/state/users/user.reducer";
import {
  AdmisionStatusType,
  Admission_Recurring_type,
  ZoneType,
} from "src/utils/constants/constant";
import { useEffect, useState } from "react";
import {
  saveExpertSessionData,
  getAdmissionRecurringData,
  updateExpertSessionData,
  getExpertSessionData,
} from "src/state/students/students.action";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import moment from "moment";
import { useWatch } from "antd/lib/form/Form";
import { getFacultyByZone, showTooltip } from "src/utils/helperFunction";
import {
  ICompanyVisitFieldProps,
  IFacultyData,
} from "src/pages/CompanyVisitUpdateCard/CompanyVisit.model";
import { branchSelector } from "src/state/branch/branch.reducer";
const { Text } = Typography;
const { Option } = Select;

function ReportExpertSessionField(props: ICompanyVisitFieldProps) {
  const { data, form, myRef, isDrawer, setShow, isEdit } = props;
  const dateFormat = "DD/MM/YYYY";
  const { id } = useParams();
  const userState = useAppSelector(userSelector);
  const studentState = useAppSelector(studentsSelector);
  const editExpertSessionData = studentState.expertSessionData?.data?.rows.find(
    (es) => es.id === Number(id)
  );
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [multiId, setMultiId] = useState<number>(0);
  const [studentDetail, setStudentDetail] = useState<number[]>([]);
  const { TextArea } = Input;
  const dispatch = useDispatch<AppDispatch>();
  const storageID: string | null = localStorage.getItem("myStorageID");
  const { userData } = useAppSelector(userSelector);
  const branchState = useAppSelector(branchSelector);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);

  const rules = {
    expert_name: [
      { required: true, message: "Please Enter Expert Name" },
      { max: 200, message: "Max 200 character long" },
    ],
    venue: [
      { required: true, message: "Please Enter Venue" },
      { max: 200, message: "Max 200 character long" },
    ],
    subject: [
      { required: true, message: "Please Enter Subject" },
      { max: 200, message: "Max 200 character long" },
    ],
    venue_datetime: [{ required: true, message: "Please Enter Venue Date" }],
    execute_by_user_id: [
      { required: true, message: "Please Select Faculty By" },
    ],
    branch_id: [{ required: true, message: "Please Select BranchId" }],
  };
  const dataView = useWatch("venue_datetime", form);

  const onReset = () => {
    setSearchParams("");
    setTimeout(() => form.resetFields());
    setMultiId(0);
  };

  const values =
    {
      ...editExpertSessionData,
      volunteers:
        (editExpertSessionData?.es_volunteers?.map((obj) =>
          Number(obj.user_id)
        ) as number[]) || [],
      venue_datetime: moment(editExpertSessionData?.venue_datetime),
      branch_id: editExpertSessionData?.branch?.id,
      execute_by_user_id: Number(editExpertSessionData?.execute_by_user_id),
    } || {};

  const studentListData =
    studentState.admissionRecurringData?.data?.rows.filter(
      (d) =>
        d.type === Admission_Recurring_type.ES &&
        (isEdit
          ? d.type_id === Number(id) || d.type_id == null
          : d.type_id == null)
    );
  useEffect(() => {
    if (isEdit && editExpertSessionData === undefined) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (isEdit && editExpertSessionData?.admission_recurings) {
      const studentList = editExpertSessionData.admission_recurings.map(
        (data) => Number(data.id)
      );

      setSelectedRowKeys(studentList as never[]);
      setStudentDetail(studentList);
      setMultiId(studentList.length);
    } else {
      form.setFieldValue("is_online", false);
      setStudentDetail([]);
    }
  }, []);

  useEffect(() => {
    if (studentState.saveExpertSession.message !== "") {
      if (studentState.saveExpertSession.hasErrors) {
        message.error(studentState.saveExpertSession.message);
      } else {
        message.success(studentState.saveExpertSession.message);
      }
    }
    dispatch(clearRemoveMessage());
  }, [studentState.saveExpertSession.message]);

  useEffect(() => {
    if (studentState.updateExpertSession.message !== "") {
      if (studentState.updateExpertSession.hasErrors) {
        message.error(studentState.updateExpertSession.message);
      } else {
        message.success(studentState.updateExpertSession.message);
      }
    }
    dispatch(clearRemoveMessage());
  }, [studentState.updateExpertSession.message]);

  const currentUserZone = userData.data.user_roles.find(
    (role) => role.id === Number(storageID)
  )?.zone;

  useEffect(() => {
    if (dataView === null) {
      form.setFieldValue("venue_datetime", undefined);
    }
  }, [dataView]);
  const facultyData = currentUserZone
    ? getFacultyByZone(
        userState.usersData.data,
        currentUserZone?.id,
        [...values?.volunteers, values.execute_by_user_id] || []
      )
    : [];

  const onSelectChange = (newSelectedRowKeys: []) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setStudentDetail(newSelectedRowKeys);
    if (newSelectedRowKeys.length > 0) {
      setMultiId(newSelectedRowKeys.length);
    } else {
      setMultiId(0);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onFinish = async (values: ISaveExpertSessionPayload) => {
    if (disableSubmitBtn) {
      setDisableSubmitBtn(false);
      const finalData = {
        ...values,
        expert_name: values.expert_name?.replaceAll(/'/g, "''"),
        subject: values.subject?.replaceAll(/'/g, "''"),
        is_online: values.is_online ? values.is_online : false,

        venue: values.venue?.replaceAll(/'/g, "''"),
        details: values.details?.replaceAll(/'/g, "''"),
        status: AdmisionStatusType.PENDING,
        admission_recurings: studentDetail as number[],
        branch_id: values.branch_id
          ? values.branch_id
          : Number(
              currentUserZone?.branches && currentUserZone?.branches?.length > 0
                ? currentUserZone?.branches[0].id
                : 0
            ),
      };
      if (studentDetail.length > 0) {
        (isEdit && id
          ? dispatch(updateExpertSessionData({ id: Number(id), ...finalData }))
          : dispatch(saveExpertSessionData(finalData))
        ).then((res) => {
          if (res.payload) {
            dispatch(getExpertSessionData());
            if (studentState.admissionRecurringData.loading === false) {
              dispatch(
                getAdmissionRecurringData({
                  ...searchParams,
                  order: "ASC",
                  orderBy: "end_date",
                  noLimit: true,
                })
              );
            }
            setStudentDetail([]);
            setShow && setShow(false);
            onReset();
            if (isEdit) {
              navigate("/");
            }
          }
        });
      } else {
        setDisableSubmitBtn(true);
        message.error("Student Detail is required");
      }
    }
  };

  const studentList: ColumnsType<[]> = [
    {
      title: "Sr No",
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: "GR ID",
      render: (record) => {
        return <span>{record?.admission?.gr_id}</span>;
      },
    },
    {
      title: "Student Name",
      render: (record) => {
        return (
          <span>
            {record?.admission?.first_name} {record?.admission?.last_name}
            <br />
            <StudentMobileNumber
              studentMobileNumber={record?.admission?.mobile_no}
            />
          </span>
        );
      },
    },
    {
      title: "Father Name",
      render: (record) => {
        const fatherNo = record?.admission?.father_mobile_no;
        return (
          <span>
            {record?.admission?.father_name || record?.admission?.middle_name}{" "}
            <br />
            {fatherNo !== null && (
              <StudentMobileNumber studentMobileNumber={fatherNo} />
            )}
          </span>
        );
      },
    },
    {
      title: "Email",
      className: "print-email gx-text-break",
      render: (record) => {
        return <span>{record?.admission?.email}</span>;
      },
    },
  ];

  const Options = facultyData.map((fd: IFacultyData) => {
    return (
      <Option value={fd?.id}>{`${fd?.first_name}  ${fd?.last_name}`}</Option>
    );
  });

  return (
    <div>
      {" "}
      <Form
        className="drawer-form"
        name="dynamic_form_nest_item"
        form={form}
        initialValues={isEdit ? values : data}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
        requiredMark={true}
      >
        {" "}
        <Row gutter={8}>
          <Col span={24}>
            <FloatLabel
              label="Expert Name"
              placeholder="Enter Expert Name"
              name="expert_name"
              required
            >
              <Form.Item name="expert_name" rules={rules.expert_name}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Venue"
              placeholder="Enter Venue"
              name="venue"
              required
            >
              <Form.Item name="venue" rules={rules.venue}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <Text strong style={{ display: "block" }}>
              Is Online<span className="gx-ml-1 gx-text-danger">*</span>
            </Text>
            <Form.Item name="is_online">
              <Radio.Group defaultValue={false}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            {currentUserZone?.type !== ZoneType.PRIVATE && (
              <FloatLabel
                label="Branch"
                placeholder="Select Branch"
                name="branch_id"
                required
              >
                <Form.Item name="branch_id" rules={rules.branch_id}>
                  <Select
                    getPopupContainer={(trigger) => trigger.parentNode}
                    allowClear
                    showSearch
                    showArrow
                    size="large"
                    // className="gx-mr-2"
                    filterOption={(value, option) =>
                      (option?.children?.toString() || "")
                        .toLowerCase()
                        .includes(value.toLowerCase().trim())
                    }
                  >
                    {branchState.branchesData.data.rows
                      .filter((branch) => branch.status === true)
                      .map((branch) => (
                        <Option value={branch.id}>{branch.code}</Option>
                      ))}
                  </Select>
                </Form.Item>
              </FloatLabel>
            )}
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Subject"
              placeholder="Enter subject"
              name="subject"
              required
            >
              <Form.Item name="subject" rules={rules.subject}>
                <Input size="large" />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Venue Date"
              placeholder="Select Date"
              name="venue_datetime"
              required
            >
              <Form.Item name="venue_datetime" rules={rules.venue_datetime}>
                <DatePicker
                  size="large"
                  placeholder=""
                  style={{ width: "100%" }}
                  format={dateFormat}
                />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Detail"
              placeholder="Enter Detail"
              name="details"
            >
              <Form.Item name="details">
                <TextArea rows={4} />
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Faculty By"
              placeholder="Select Faculty"
              name="execute_by_user_id"
              required
            >
              <Form.Item
                name="execute_by_user_id"
                rules={rules.execute_by_user_id}
              >
                <Select allowClear size="large">
                  {Options}
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <FloatLabel
              label="Attendance By "
              placeholder="Select Attendance"
              name="volunteers"
            >
              <Form.Item name="volunteers">
                <Select allowClear showArrow mode="multiple" size="large">
                  {Options}
                </Select>
              </Form.Item>
            </FloatLabel>
          </Col>
          <Col span={24}>
            <Card className="faculty-dashboard-card gx-mt-2">
              <div className="gx-d-flex gx-justify-content-between">
                <h3>
                  Student Detail ({studentListData && studentListData.length})
                </h3>
                {multiId > 0 && (
                  <div className="gx-text-right">
                    Total Selected Student{" "}
                    <span className="selected-stud">({multiId})</span>
                  </div>
                )}
              </div>
              <div className="card-body faculty-dashboard company-visit-card student-detail-dashboard">
                <TableComponent
                  rowSelection={rowSelection}
                  columns={studentList}
                  rowKey={(record) => record.id}
                  dataSource={studentListData}
                  loading={false}
                />
              </div>
            </Card>
          </Col>
        </Row>
        {!isDrawer ? (
          <div className="gx-mt-3 gx-d-flex gx-justify-content-lg-end footer-button">
            <Button type="primary" htmlType="submit" className="gx-my-0">
              Update
            </Button>
            <Button className="btn-cancel" onClick={() => navigate("/")}>
              Cancel
            </Button>
          </div>
        ) : (
          <Form.Item className="modal-btn-grp" style={{ display: "none" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="btn-submit"
              ref={myRef}
            >
              Submit
            </Button>
          </Form.Item>
        )}
      </Form>
    </div>
  );
}

export default ReportExpertSessionField;
